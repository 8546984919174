var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("date-picker-component", {
        attrs: { "fetch-date": _vm.nowDate },
        on: {
          "update:fetchDate": function($event) {
            _vm.nowDate = $event
          },
          "update:fetch-date": function($event) {
            _vm.nowDate = $event
          }
        }
      }),
      _vm._v(" "),
      _c("data-chart", {
        attrs: { "chart-data": _vm.chartData, options: _vm.chartOptions }
      }),
      _vm._v(" "),
      _c("data-table", { attrs: { "loaded-records": _vm.loadedRecords } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }