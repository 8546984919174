import { render, staticRenderFns } from "./loginForm.vue?vue&type=template&id=2ac359c4&scoped=true&"
import script from "./loginForm.vue?vue&type=script&lang=js&"
export * from "./loginForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac359c4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCol,VContainer,VContent,VRow,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/mnt/c/Users/r-kojima/PhpstormProjects/Aquarium-Status-Viewer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ac359c4')) {
      api.createRecord('2ac359c4', component.options)
    } else {
      api.reload('2ac359c4', component.options)
    }
    module.hot.accept("./loginForm.vue?vue&type=template&id=2ac359c4&scoped=true&", function () {
      api.rerender('2ac359c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/loginForm.vue"
export default component.exports