var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", [
    _c("button", { attrs: { type: "button" }, on: { click: _vm.previous } }, [
      _vm._v("prev")
    ]),
    _vm._v("\n\t" + _vm._s(_vm.fetchDate) + "\n\t"),
    _c("button", { attrs: { type: "button" }, on: { click: _vm.next } }, [
      _vm._v("next")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }