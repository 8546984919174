var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.loadedRecords, function(record) {
        return _c("tbody", [
          _c("td", [_vm._v(_vm._s(record.date))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(record.water))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(record.temperature))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(record.humidity))])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("日にち")]),
      _vm._v(" "),
      _c("th", [_vm._v("水温")]),
      _vm._v(" "),
      _c("th", [_vm._v("気温")]),
      _vm._v(" "),
      _c("th", [_vm._v("湿度")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }