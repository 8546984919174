var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _vm.isLogIn
        ? _c("dashboard")
        : _c("loginForm", {
            attrs: { "is-log-in": _vm.isLogIn },
            on: {
              "update:isLogIn": function($event) {
                _vm.isLogIn = $event
              },
              "update:is-log-in": function($event) {
                _vm.isLogIn = $event
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }